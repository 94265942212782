<template>
    <div id="index">
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="mb-4">Создание заказа</h2>
                <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
                <h5>Укажите необходимые характеристики</h5>
                <hr>
            </div>     
            
            <FormParams v-bind:elements="elements" ref="formParams"></FormParams>

            <div class="clearfix">
                <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
                <div class="float-right"><a @click="nextStep" class="text-dark">Следующий шаг &rarr;</a></div>
            </div>  

            <!-- <div class="text-center text-yellow font-italic mt-5">
                {{offerCount}} подходящих предложений
            </div> -->
        </div>
    </div>
</template>

<script>
import FormParams from '../../Modules/FormParams.vue'

export default {
    name: 'Params',
    props: ['step', 'progress'],
    components: {
      FormParams
    },
    data() {
        return {
            elements: {},
            offerCount: 0,
        }
    },
    methods: {
        getOffer(){
            this.api.get("tech/offers", {"tech_category_id" : this.$parent.formData.category_id}).then(
                function (response) {
                    this.offerCount = response.data;
                }.bind(this)
            );
        },
        updateElements(){
            var self = this;
            self.api.get('reference-book/tech-params', {
                'tech_category_id': self.$parent.formData.category_id,
                'type_data_id': 3
            })
            .then(function(response) {
                self.elements = response.data;
            });
            this.getOffer();
        },
        prevStep() {
            this.$parent.changeStep(this.step - 1);
        },
        nextStep() {
            if(false === this.$refs.formParams.check()){
                return;
            }
            this.$parent.setFormData({"params": this.helpers.getValuesFromElements(this.elements)});
            this.$parent.changeStep(this.step + 1);
        }
    }
}
</script>