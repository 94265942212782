<template>
  <div id="index">
    <div class="container">
      <div class="text-center">
        <h2 class="mb-4">Добавить технику</h2>
        <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
        <h5>Укажите цены и<br>способы оплаты</h5>
        <hr>
      </div> 
      <b-form class="mb-5">
        <b-form-group label="Стоимость в час:" label-for="input-1">
            <b-form-input
                id="input-2"
                v-model="form.price"
                :state="validateState('price')"
            ></b-form-input>
            <FormInputValidate :vdata="$v.form.price"></FormInputValidate>
        </b-form-group>
        <b-form-group label="Стоимость доставки на объект:" label-for="input-1">
            <b-form-input
                id="input-2"
                v-model="form.price_delivery"
                :state="validateState('price_delivery')"
            ></b-form-input>
            <FormInputValidate :vdata="$v.form.price_delivery"></FormInputValidate>
        </b-form-group>
        <b-form-group label="Минимальный объем работ, ч:" label-for="input-1">
            <b-form-input
                id="input-2"
                v-model="form.min_hour"
                :state="validateState('min_hour')"
            ></b-form-input>
            <FormInputValidate :vdata="$v.form.min_hour"></FormInputValidate>
        </b-form-group>
        <b-form-group label="Способы оплаты:" label-for="input-1">
            <b-form-checkbox name="pay_method_1" v-model="form.pay_method_1" v-bind:value="1">Наличные</b-form-checkbox>
            <b-form-checkbox name="pay_method_2" v-model="form.pay_method_2" v-bind:value="1">Перевод на карту</b-form-checkbox>
            <b-form-checkbox name="pay_method_3" v-model="form.pay_method_3" v-bind:value="1">Оплата по счету (с НДС)</b-form-checkbox>
            <b-form-checkbox name="pay_method_4" v-model="form.pay_method_4" v-bind:value="1">Оплата по счету (без НДС)</b-form-checkbox>
        </b-form-group>
      </b-form>
      <div class="clearfix mt-2">
          <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
          <div class="float-right"><a @click="nextStep" class="text-dark">Следующий шаг &rarr;</a></div>
      </div>
      <br><br>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, decimal, integer } from 'vuelidate/lib/validators'
import { decimal2 } from '../../../class/validate'
import FormInputValidate from "../../Modules/FormInputValidate.vue";

export default {
    name: 'Money',
    components: {
      FormInputValidate
    },
    mixins: [validationMixin],
    props: ['step', 'progress'],
    data() {
        return {
            form: {
              price: null,
              price_delivery: null,
              min_hour: null,
            }
        }
    },
    validations: {
      form: {
        price: {
          required,
          decimal,
          decimal2,
        },
        price_delivery: {
          required,
          decimal,
          decimal2,
        },
        min_hour: {
          required,
          integer,
        },
      }
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      prevStep() {
        this.$parent.changeStep(this.step - 1);
      },
      nextStep() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.$parent.changeStep(this.step + 1);
        this.$parent.setFormData(this.form);
      }
    }
}
</script>