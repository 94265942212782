<template>
    <div id="index">
        <div class="container">
            <div class="text-center order-major-title">
                <LinkBack></LinkBack>
                <OrderStatusLabel v-bind:order="order"> </OrderStatusLabel>
                <h2 class="mb-4 mt-3">Заказ № {{ order.id }}</h2>
                <hr />
            </div>

            <h4 class="order-details-heading">Детали заказа:</h4>

            <div class="mb-3">
                <div class="order-detail">
                    <strong>{{ order.category }}</strong>
                </div>
            </div>

            <div class="mb-3 mt-3">
                <div class="order-details-grid">
                    <div>
                        <div class="order-detail">
                            <strong>Дата подачи:</strong> {{ order.datestart }}
                        </div>
                        <div class="order-detail">
                            <strong>Время подачи:</strong> {{ order.timestart }}
                        </div>
                    </div>
                    <div>
                        <div class="order-detail">
                            <strong>Дата окончания:</strong>
                            {{ order.datefinish }}
                        </div>
                        <div class="order-detail">
                            <strong>Время окончания:</strong>
                            {{ order.timefinish }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="order-details-grid">
                <div>
                    <!--div class="mb-3">
                        <div class="order-detail">
                            <strong>Объем работ:</strong> {{ order.worktime }}
                        </div>
                    </div-->

                    <div class="mb-3">
                        <ValueParams
                            v-bind:elements="order.params"
                        ></ValueParams>
                    </div>

                    <div class="mb-3">
                        <div class="order-detail">
                            <strong>Регион:</strong> {{ order.region }}
                        </div>
                        <div class="order-detail">
                            <strong>Город:</strong> {{ order.city }}
                        </div>
                        <div class="order-detail">
                            <strong>Адрес:</strong> {{ order.address }}
                        </div>
                    </div>

                    <div class="order-detail mb-4">
                        <strong>Описание работ:</strong> {{ order.todo }}
                    </div>

                    <div class="mb-3">
                        <div class="order-detail">
                            <strong>Желаемые способы оплаты:</strong>
                            <ValuePayments v-bind:order="order"></ValuePayments>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="order-item-tech-img">
                        <img
                            v-for="photo in order.photos"
                            :key="photo"
                            :src="photo"
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <template v-if="null === activeRespond">
                <h4 class="order-details-heading">Отклики:</h4>
                <div class="order-items">
                    <RespondsOrder
                        v-for="respond in responses"
                        :key="respond.id"
                        v-bind:respond="respond"
                        v-bind:modal="true"
                    ></RespondsOrder>
                </div>
            </template>
            <template v-if="null !== activeRespond && (1 == order.status || 2 == order.status)">
                <div class="text-center mt-5" v-if="null !== order.worker_confirm_at">
                    <router-link :to="{'name': 'chat', 'params': {target_id: order.worker_id, order_id: order.id, client: true}}">
                        <a class="btn btn-lg btn-outline-secondary">Начать чат</a>
                    </router-link>
                    <router-link :to="{'name': 'showContact', 'params': {user_id: order.worker_id, order_id: order.id, client: true}}">
                        <a class="btn btn-lg btn-outline-secondary ml-3">Контакты</a>
                    </router-link>
                </div>
                <div class="text-center m-5" v-if="null === order.worker_confirm_at && null !== order.worker_selected_at">
                    <div class="text-center m-5" v-if="new Date().getTime() > order.workerSelectedAtStampEnd">
                        <div>Исполнитель не ответил</div>
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-secondary btn-lg"
                            @click="cancelWorker()"
                            >Отменить выбор исполнителя</a
                        >
                    </div>
                    <div class="text-center m-5" v-if="new Date().getTime() < order.workerSelectedAtStampEnd">
                        Ожидается подтверждение исполнителя
                    </div>
                </div>
                <h4 class="order-details-heading">Исполнитель:</h4>
                <div class="order-items">
                    <RespondsOrder
                        v-bind:respond="activeRespond"
                        v-bind:modal="false"
                    ></RespondsOrder>
                </div>
            </template>
            <OrderCompleteModal
                v-bind:order="order"
                v-bind:client="true"
            ></OrderCompleteModal>
            <OrderControlBtn
                v-bind:order="order"
            ></OrderControlBtn>
        </div>

        <b-modal
            ref="modal-choose-worker"
            title="Хотите выбрать это предложение?"
            hide-footer
            hide-header
        >
            <div>
                <h4 class="text-center mb-5 mt-5">
                    Хотите выбрать это предложение?
                </h4>

                <RespondsOrder
                    v-bind:respond="selectRespond"
                    v-bind:modal="false"
                ></RespondsOrder>

                <div class="text-center mt-5">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-success btn-lg btn-block"
                        @click="confirmWorker()"
                        >Подтвердить</a
                    >
                    <a
                        href="javascript:void(0)"
                        class="btn btn-outline-secondary btn-lg btn-block mt-3"
                        @click="hideModal()"
                        >Отмена</a
                    >
                </div>
            </div>
        </b-modal>

        <b-modal
            ref="modal-confirm-worker"
            title="Выбрано!"
            hide-footer
            hide-header
        >
            <div class="text-center">
                <h4 class="mb-5 mt-5">Исполнитель выбран!</h4>
                <img src="icons/clock.svg" style="width: 50px" alt="" />
                <p class="mt-4">
                    Дождитесь подтверждения сделки от специалиста, после этого
                    вам автоматически будут отправлены его контактные данные
                </p>
                <div class="mt-5">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-outline-secondary btn-lg btn-block mt-3"
                        @click="hideModal()"
                        >В заказ</a
                    >
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>
import RespondsOrder from "./Search/RespondsOrder.vue";
import ValueParams from "../Modules/ValueParams.vue";
import ValuePayments from "../Modules/ValuePayments.vue";
import OrderCompleteModal from "./OrderCompleteModal.vue";
import OrderControlBtn from "./OrderControlBtn.vue";
import OrderStatusLabel from "./OrderStatusLabel.vue";
import LinkBack from "./../Modules/LinkBack.vue";

export default {
    name: "OrderDetails",
    components: {
        RespondsOrder,
        ValueParams,
        ValuePayments,
        OrderCompleteModal,
        OrderControlBtn,
        OrderStatusLabel,
        LinkBack
    },
    props: ["order_id"],
    data() {
        return {
            responses: [],
            order: {},
            activeRespond: null,
            selectRespond: {},
            waitConfirmTimeEnd: null,
        };
    },
    created: function() {
        if (!this.order_id) {
            this.$router.push({ path: "/orders/client" });
        }
        this.api.get("order/get", { order_id: this.order_id }).then(
            function(response) {
                this.order = response.data;
                console.log(this.order.workerSelectedAtStampEnd);
            }.bind(this)
        );
        this.getResponse();
        
    },
    methods: {
        getResponse() {
            this.api.get("respond/get", { order_id: this.order_id }).then(
                function(response) {
                    this.responses = response.data;
                    this.responses.forEach(
                        function(resp) {
                            if (1 === resp.active) {
                                this.activeRespond = resp;
                            }
                        }.bind(this)
                    );
                }.bind(this)
            );
        },
        confirmWorker() {
            this.api
                .get("respond/select", { resp_id: this.selectRespond.id })
                .then(
                    function() {
                        this.getResponse();
                        this.$refs["modal-choose-worker"].hide();
                        this.$refs["modal-confirm-worker"].show();
                    }.bind(this)
                );
        },
        cancelWorker: function(){
            this.api
                .get("respond/worker_cancel", { order_id: this.order.id })
                .then(
                    function() {
                        this.activeRespond = null;
                        this.responses = [];
                    }.bind(this)
                );
        }, 
        hideModal() {
            this.$refs["modal-choose-worker"].hide();
            this.$refs["modal-confirm-worker"].hide();
        }
    }
};
</script>