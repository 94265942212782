<template>
  <div id="index">
    <div class="container">
      <div class="text-center">
        <h2 class="mb-4">Добавить технику</h2>
        <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
        <h5>Где будет доступна <br> данная услуга?</h5>
        <hr>
      </div> 
      <b-form class="mb-5">
        <b-form-group id="input-group-3" label="Регион:" label-for="select-1">
            <b-form-select 
              @change="onRegionSelected($event)" 
              v-model="form.region_id" 
              :options="options.regions"
              :state="validateState('region_id')"
            ></b-form-select>
            <FormInputValidate :vdata="$v.form.region_id"></FormInputValidate>
        </b-form-group>
        <b-form-group id="input-group-3" label="Город:" label-for="select-2" v-if="options.cities.length">
            <b-form-select 
              v-model="form.city_id" 
              placeholder="Выберите город" 
              :options="options.cities"
              :state="validateState('city_id')"
            ></b-form-select>
            <FormInputValidate :vdata="$v.form.city_id"></FormInputValidate>
        </b-form-group>
      </b-form>
      <div class="clearfix mt-2 text-center">
          <a href="javascript:" @click="nextStep" class="btn btn-lg btn-success mt-5">Добавить</a>
      </div>
      <div class="clearfix mt-2 text-center">
          <div><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
      </div>
      <br><br>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators'
import FormInputValidate from "../../Modules/FormInputValidate.vue";

export default {
  name: 'Location',
  components: {
      FormInputValidate
    },
  mixins: [validationMixin],
  props: ['step', 'progress'],
  data() {
      return {
          if_property: false,
          options: {
            regions: [],
            cities: []
          },
          form: {
            region_id: null,
            city_id: null,
          }
      }
  },
  validations: {
    form: {
      region_id: {
        required,
      },
      city_id: {
        required,
      },
    }
  },
  created: function () {
    var self = this;
    self.api.get('reference-book/regions')
    .then(function(response) {
      self.options.regions = response.data;
    }); 
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onRegionSelected() {
        var self = this;
        self.api.get('reference-book/cities', {"region_id": this.form.region_id})
        .then(function(response) {
          self.options.cities = response.data;
        }); 
    },
    prevStep() {
      this.$parent.changeStep(this.step - 2);
    },
    nextStep() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      var self = this;
      self.$parent.setFormData(self.form);
      self.$parent.saveTech().then(function(){
        self.$parent.changeStep(self.step + 1);
      });
    }
  }
}
</script>