<template>
    <div id="index">
        <div class="container">
            <div class="text-center mt-5 mb-4">
                <h2 class="mb-4">Реф. программа</h2>
                <hr>
            </div>  

            <div class="text-center pb-5">
                <h5 class="container-header">Мой промокод:</h5>
                <span class="btn btn-outline-warning"><strong>{{this.$store.state.user.referral_code}}</strong></span>
                <div class="row mt-4 mb-5">
                    <div class="col-7">
                        <a href="javascript:" @click="copy" class="btn btn-outline-secondary btn-block">
                            <span class="small">{{this.link}}</span>
                            <img src="/icons/copy.svg" class="icon-copy" alt="">
                        </a>
                    </div>
                    <div class="col-5">
                        <a href="javascript:" class="btn btn-block text-dark shadow-button active m-0">Поделиться</a>
                    </div>
                </div>
            </div>

            <h5 class="container-header">Кол-во рефералов: <strong>{{this.$store.state.user.referral_list.length}}</strong></h5>
            <table class="table table-borderless bb-table">
                <tr v-for="referral in this.$store.state.user.referral_list" :key="referral.id">
                    <td>
                        {{referral.fullname}}
                    </td>
                    <td class="text-right text-success">
                        + {{referral.referrer_payed}} Р
                    </td>
                </tr>
            </table>

        </div>
    </div>
</template>


<script>

export default {
    name: 'ProfileReferals',
    data() {
      return {
        link: null,
      }
    },
    created: function () {
        var self = this;
        setTimeout(function(){
            self.link = 'https://' + window.location.hostname + '/#/ref?code=' + self.$store.state.user.referral_code;
        }, 1000);
    },
    methods: {
        copy() {
            // var code = document.querySelector('#text'); // #text - блок из которого нужно скопировать
            var code = this.link; // #text - блок из которого нужно скопировать
            var area = document.createElement('textarea');
            document.body.appendChild(area);  
            area.value = code;
            area.select();
            document.execCommand("copy");
            document.body.removeChild(area); 
            alert('Ссылка скопирована в буфер обмена');
        }
    }
}
</script>