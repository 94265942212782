var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"index"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"orders-filter mb-4 clearfix"},[_c('a',{staticClass:"btn btn-sm mr-2",class:{
                    'btn-secondary': 1 === _vm.tab,
                    'btn-outline-secondary': 1 !== _vm.tab,
                },attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.setTab(1)}}},[_vm._v("Откликнулся")]),_c('a',{staticClass:"btn btn-sm mr-2",class:{
                    'btn-secondary': 2 === _vm.tab,
                    'btn-outline-secondary': 2 !== _vm.tab,
                },attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.setTab(2)}}},[_vm._v("В работе")]),_c('a',{staticClass:"btn btn-sm mr-2",class:{
                    'btn-secondary': 3 === _vm.tab,
                    'btn-outline-secondary': 3 !== _vm.tab,
                },attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.setTab(3)}}},[_vm._v("Выполненные")])]),_c('div',{staticClass:"orders"},_vm._l((_vm.orders.items),function(order){return _c('OrderItem',{key:order.id,attrs:{"worker":true,"order":order}})}),1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Мои заказы")]),_c('hr')])}]

export { render, staticRenderFns }