export default class helpers {
  getValuesFromElements(array) {
    let res = [];
    for (var key in array) { 
      if(undefined !== array[key].value) {
        res.push(
          {
            "param_id": array[key].id,
            "value": array[key].value
          }
        );
      }
      if(undefined !== array[key].value_start && undefined !== array[key].value_end) {
        res.push(
          {
            "param_id": array[key].id,
            "value_start": array[key].value_start,
            "value_end": array[key].value_end
          }
        );
      }
    }
    return res;
  }
}