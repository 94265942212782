<template>
  <div id="index">
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <h2>Редактирование</h2>
          <hr>
          <div class="row mb-4">
            <div class="col-4">
              <img v-bind:src="form.avatar" class="img-fluid rounded-circle">
            </div>
            <div class="col-6">
              <h4>{{ this.$store.state.user.firstname }} {{ this.$store.state.user.lastname }}</h4>
              <!-- <div class="rating">
                ***** <span>4.9</span>
              </div> -->
              <span class="text-muted"><small>{{ this.$store.state.user.created_at }}</small></span>
            </div>
          </div>

            <b-form>
                <b-form-group>
                    <b-form-group>
                        <b-form-radio v-model="form.company_type" value="1" @click="this.form.$model.company_type=1">Физ.Лицо</b-form-radio>
                        <b-form-radio v-model="form.company_type" value="2" @click="this.form.$model.company_type=2">Юр.Лицо</b-form-radio>
                    </b-form-group>
                </b-form-group>
                <b-form-group v-show="this.$v.form.$model.company_type == 2">
                  <b-form-input
                    id="firstname"
                    v-model="form.company_name"
                    type="text"
                    placeholder="Название компании"
                    value="123"
                    >
                  </b-form-input>
                </b-form-group>
                <b-form-group v-show="this.$v.form.$model.company_type == 2">
                  <b-form-input
                    id="firstname"
                    v-model="form.company_inn"
                    type="text"
                    placeholder="ИНН"
                    value="123"
                    >
                  </b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        id="firstname"
                        v-model="form.firstname"
                        type="text"
                        :state="validateState('firstname')"
                        placeholder="Имя"
                        value="123"
                        >
                    </b-form-input>
                    <FormInputValidate :vdata="$v.form.firstname"></FormInputValidate>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        id="lastname"
                        v-model="form.lastname"
                        type="text"
                        :state="validateState('lastname')"
                        placeholder="Фамилия"
                        >
                    </b-form-input>
                    <FormInputValidate :vdata="$v.form.lastname"></FormInputValidate>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        id="email"
                        v-model="form.email"
                        type="text"
                        :state="validateState('email')"
                        placeholder="Email"
                        >
                    </b-form-input>
                    <FormInputValidate :vdata="$v.form.email"></FormInputValidate>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        id="contact_phone"
                        v-model="form.contact_phone"
                        type="text"
                        :state="validateState('contact_phone')"
                        placeholder="Контактный телефон"
                        >
                    </b-form-input>
                    <FormInputValidate :vdata="$v.form.contact_phone"></FormInputValidate>
                </b-form-group>
                 <b-form-group>
                        <b-form-select @change="onRegionSelected($event)" v-model="form.region_id" :options="options.regions" :state="validateState('region_id')"></b-form-select>
                        <FormInputValidate :vdata="$v.form.region_id"></FormInputValidate>
                </b-form-group>
                 <b-form-group>
                        <b-form-select v-model="form.city_id" placeholder="Выберите город" :options="options.cities" :state="validateState('city_id')"></b-form-select>
                        <FormInputValidate :vdata="$v.form.city_id"></FormInputValidate>
                </b-form-group>
                <b-form-file
                    v-model="file"
                    placeholder="Выбрать аватарку..."
                    drop-placeholder="Перетащить изображение..."
                ></b-form-file>
                <b-button @click="saveProfile()" variant="primary" class="my-4 btn-lg">Сохранить</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import mixinAuth from './../mixins/mixinAuth';
import { validationMixin } from "vuelidate";
import { required, numeric, minLength, maxLength, email } from 'vuelidate/lib/validators'
import FormInputValidate from "./Modules/FormInputValidate.vue";

export default {
  name: 'MyProfile',
  components: {
      FormInputValidate
  },
  mixins: [mixinAuth, validationMixin],
  data() {
    return {
      file: null,
      options: {
        regions: [],
        cities: []
      }
    }
  },
  computed: {
    form: function () {
      return this.$store.state.user
    }
  },
  validations: {
      form: {
        firstname: {
          required,
        },
        lastname: {
          required,
        },
        email: {
          required,
          email,
        },
        contact_phone: {
          required,
          numeric,
          minLength: minLength(11),
          maxLength: maxLength(11),
        },
        region_id: {
          required,
        },
        city_id: {
          required,
        },
      }
    },
  created: function () {
    var self = this;
    self.api.get('reference-book/regions')
    .then(function(response) {
      self.options.regions = response.data;

      setTimeout(function(){
          self.onRegionSelected();
      }, 100);
    }); 
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onRegionSelected() {
      var self = this;
      self.api.get('reference-book/cities', {"region_id": this.form.region_id})
      .then(function(response) {
        self.options.cities = response.data;
      }); 
    },
    saveProfile() {
      var self = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      var reader = new FileReader();
      if(self.file) {
        reader.readAsDataURL(self.file);
        reader.onload = function() {
          if(reader.result.length < 1 * 1024 * 1024) {
            self.uploadData(self, reader.result);
          } else {
            alert('Загружаемый файл должен быть не более 1 Мб');
          }
        }
      } else {
        self.uploadData(self, null);
      }
    },
    uploadData(self, fileData) {
      self.api.get('profile/save', {
        'form': self.form, 
        'avatar': fileData
      })
      .then(function() {
        self.loadUserInfo();
      }); 
    }
  }
}
</script>