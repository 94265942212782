<template>
    <div id="index">
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="mb-4">Создание заказа</h2>
                <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
                <h5>Укажите дату начала<br>и&nbsp;окончания работ</h5>
                <hr>
            </div>     

            <div>
                <label for="example-datepicker">Начало работ</label>
                <b-form-datepicker 
                    id="datepicker_time_start" 
                    v-model="form.date_start" 
                    :state="validateState('date_start')"
                    class="mb-2 no-border-input"
                    placeholder="Выберите дату">
                </b-form-datepicker>
                <FormInputValidate :vdata="$v.form.date_start"></FormInputValidate>
                <b-form-timepicker 
                    id="timepicker_time_start" 
                    class="no-border-input"
                    v-model="form.time_start" 
                    :state="validateState('time_start')"
                    placeholder="Выберите время"
                    v-bind="labels[locale] || {}"
                    :locale="locale">
                </b-form-timepicker>
                <FormInputValidate :vdata="$v.form.time_start"></FormInputValidate>
            </div>

            <div class="mt-4 mb-5">
                <label for="example-datepicker">Окончание работ</label>
                <b-form-datepicker 
                    id="datepicker_date_finish" 
                    v-model="form.date_finish" 
                    :state="validateState('date_finish')"
                    class="mb-2 no-border-input"
                    placeholder="Выберите дату">
                </b-form-datepicker>
                <FormInputValidate :vdata="$v.form.date_finish"></FormInputValidate>
                <b-form-timepicker 
                    id="timepicker_time_finish" 
                    class="no-border-input"
                    v-model="form.time_finish" 
                    :state="validateState('time_finish')"
                    placeholder="Выберите время"
                    v-bind="labels[locale] || {}"
                    :locale="locale">
                </b-form-timepicker>
                <FormInputValidate :vdata="$v.form.time_finish"></FormInputValidate>
                <template>              
                    <div v-if="time_error" style="font-size: 80%;color: #dc3545;">Дата начала работ должна быть раньше даты окончания</div>
                </template>
            </div>

            <div class="clearfix">
                <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
                <div class="float-right"><a @click="nextStep" class="text-dark">Следующий шаг &rarr;</a></div>
            </div>  

            <!-- <div class="text-center text-yellow font-italic mt-5">
                {{offerCount}} подходящих предложений
            </div> -->
        </div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from 'vuelidate/lib/validators'
import FormInputValidate from "../../Modules/FormInputValidate.vue";

export default {
    name: 'DateTime',
    components: {
      FormInputValidate
    },
    mixins: [validationMixin],
    props: ['step', 'progress'],
    data() {
        return {
            form: {},
            time_error: false,
            locale: 'ru',
            locales: [
                { value: 'ru', text: 'Русский' }
            ],
            labels: {
                ru: {
                    labelHours: 'Часы',
                    labelMinutes: 'Минуты',
                    labelSeconds: 'Секунды',
                    labelIncrement: 'Увеличить',
                    labelDecrement: 'Уменьшить',
                    labelSelected: 'Выберите время',
                    labelNoTimeSelected: 'Время не выбрано',
                    labelCloseButton: 'Закрыть'
                }
            },
            offerCount: 0,
        }
    },
    validations: {
        form: {
            date_start: {
                required
            },
            time_start: {
                required
            },
            date_finish: {
                maxLength: maxLength(999),
            },
            time_finish: {
                maxLength: maxLength(999),
            },
        }
    },
    methods: {
        updateElements() {
            this.getOffer();
        },
        getOffer(){
            this.api.get("tech/offers", {
                    "tech_category_id" : this.$parent.formData.category_id,
                    "region_id" : this.$parent.formData.region_id,
                    "city_id" : this.$parent.formData.city_id,
                }).then(
                function (response) {
                    this.offerCount = response.data;
                    this.$parent.offerCount = this.offerCount;
                }.bind(this)
            );
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        prevStep() {
            this.$parent.changeStep(this.step - 1);
        },
        nextStep() {
            this.$v.form.$touch();
            let ds = Date.parse(this.form.date_start + 'T' + this.form.time_start);
            let df = Date.parse(this.form.date_finish + 'T' + this.form.time_finish);
            if(ds > df) {
                this.time_error = true;
                return;
            } else {
                this.time_error = false;
            }
            if (this.$v.form.$anyError) {
                return;
            }
            this.$parent.setFormData(this.form);
            this.$parent.changeStep(this.step + 1);
        }
    }
}
</script>