var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(2 === _vm.order.status)?_c('div',{staticClass:"text-center mt-5"},[_c('a',{staticClass:"btn btn-lg btn-outline-secondary",attrs:{"href":"javascript:"},on:{"click":_vm.openModal}},[_vm._v("Завершить заказ ")])]):_vm._e(),_c('b-modal',{attrs:{"id":"orderCompleteModal","title":"Завершение заказа","hide-footer":"","hide-header":""}},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mb-5 mt-5"},[_vm._v(" Вы действительно хотите завершить заказ? ")]),_c('div',{staticClass:"mt-5"},[_c('router-link',{staticClass:"btn btn-secondary btn-lg btn-block mt-3",attrs:{"to":{
                        name: 'orderComplete',
                        params: {
                            order_id: _vm.order.id,
                            client: _vm.client,
                            worker: _vm.worker
                        }
                    }}},[_vm._v("Завершить")])],1),_c('div',{staticClass:"mt-1"},[_c('a',{staticClass:"btn btn-outline-secondary btn-lg btn-block mt-3",on:{"click":_vm.closeModal}},[_vm._v("Отмена ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }