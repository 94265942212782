<template>
    <div id="index">
        <div class="container">
            <div class="text-center order-major-title">
                <LinkBack></LinkBack>
                <OrderStatusLabel v-bind:order="order"> </OrderStatusLabel>
                <h2 class="mb-4 mt-3">Заказ № {{ order.id }}</h2>
                <hr />
            </div>

            <h4 class="order-details-heading">Детали заказа:</h4>

            <div class="mb-3">
                <div class="order-detail">
                    <strong>{{ order.category }}</strong>
                </div>
            </div>

            <div class="mb-3 mt-3">
                <div class="order-details-grid">
                    <div>
                        <div class="order-detail">
                            <strong>Дата подачи:</strong> {{ order.datestart }}
                        </div>
                        <div class="order-detail">
                            <strong>Время подачи:</strong> {{ order.timestart }}
                        </div>
                    </div>
                    <div>
                        <div class="order-detail" style="display:none;">
                            <strong>Дата окончания:</strong>
                            {{ order.datefinish }}
                        </div>
                        <div class="order-detail" style="display:none;">
                            <strong>Время окончания:</strong>
                            {{ order.timefinish }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="order-details-grid">
                <div>
                    <!-- div class="mb-3" style="display:none;">
                        <div class="order-detail">
                            <strong>Объем работ:</strong> {{ order.worktime }}
                        </div>
                    </div -->

                    <div class="mb-3">
                        <ValueParams
                            v-bind:elements="order.params"
                        ></ValueParams>
                    </div>

                    <div class="mb-3">
                        <div class="order-detail">
                            <strong>Регион:</strong> {{ order.region }}
                        </div>
                        <div class="order-detail">
                            <strong>Город:</strong> {{ order.city }}
                        </div>
                        <div class="order-detail">
                            <strong>Адрес:</strong> {{ order.address }}
                        </div>
                    </div>

                    <div class="order-detail mb-4">
                        <strong>Описание работ:</strong> {{ order.todo }}
                    </div>

                    <div class="mb-3">
                        <div class="order-detail">
                            <strong>Желаемые способы оплаты:</strong>
                            <ValuePayments v-bind:order="order"></ValuePayments>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="order-item-tech-img">
                        <img
                            v-for="photo in order.photos"
                            :key="photo"
                            :src="photo"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <OrderRespondModal v-bind:order="order"> </OrderRespondModal>
        <div class="text-center mt-5" v-if="2 == order.status">
            <router-link :to="{'name': 'chat', 'params': {target_id: order.user_id, order_id: order.id, worker: true}}">
                <a class="btn btn-lg btn-outline-secondary">Начать чат</a>
            </router-link>
            <router-link :to="{'name': 'showContact', 'params': {user_id: order.user_id, order_id: order.id, worker: true}}">
                <a class="btn btn-lg btn-outline-secondary ml-3">Контакты</a>
            </router-link>
        </div>
        <div class="text-center m-5" v-if="1 == order.status && null === order.worker_confirm_at && null !== order.worker_selected_at">
            <div class="text-center m-5" v-if="new Date().getTime() > order.workerSelectedAtStampEnd">
                Время подтверждения истекло
            </div>
            <div class="text-center m-5" v-if="new Date().getTime() < order.workerSelectedAtStampEnd">
                Вас выбрали исполнителем, подтвердите свою готовность или отмените заказ
                <div class="text-center mt-5" style="">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-success btn-lg"
                        @click="confirmWorker()"
                        >Подтвердить</a
                    >&nbsp;
                    <a
                        href="javascript:void(0)"
                        class="btn btn-outline-secondary btn-lg"
                        @click="cancelWorker()"
                        >Отмена</a
                    >
                </div>
            </div>
        </div>
        <OrderCompleteModal
            v-bind:order="order"
            v-bind:worker="true"
        ></OrderCompleteModal>
    </div>
</template>


<script>
import ValueParams from "../Modules/ValueParams.vue";
import ValuePayments from "../Modules/ValuePayments.vue";
import OrderCompleteModal from "./OrderCompleteModal.vue";
import OrderRespondModal from "./OrderRespondModal.vue";
import OrderStatusLabel from "./OrderStatusLabel.vue";
import LinkBack from "./../Modules/LinkBack.vue";
import mixinAuth from './../../mixins/mixinAuth';

export default {
    name: "OrderDetailsWorker",
    props: ["order_id"],
    components: {
        ValueParams,
        ValuePayments,
        OrderCompleteModal,
        OrderRespondModal,
        OrderStatusLabel,
        LinkBack
    },
    mixins: [mixinAuth],
    data() {
        return {
            order: {}
        };
    },
    created: function() {
        if (!this.order_id) {
            this.$router.push({ path: "/orders/search" });
        }
        this.api.get("order/get", { order_id: this.order_id }).then(
            function(response) {
                this.order = response.data;
            }.bind(this)
        );
    },
    methods: {
        confirmWorker: function(){
            if(this.$store.state.user.balance < 200) {
                alert('Недостаточно средств для участия в заказе, пополните баланс на 200р.');
                return;
            }
            this.api
                .get("respond/worker_confirm", { order_id: this.order.id })
                .then(
                    function() {
                        this.order.status = 2;
                        this.loginUser(this.$store.state.token);
                    }.bind(this)
                );
        }, 
        cancelWorker: function(){
            this.api
                .get("respond/worker_cancel", { order_id: this.order.id })
                .then(
                    function() {
                        this.$router.push({ path: "/orders/search" });
                    }.bind(this)
                );
        }, 
    }
};
</script>