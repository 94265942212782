<template>
    <div id="index">
        <div class="container">
            <div class="text-center mt-5 mb-4">
                <h2 class="mb-4">Вывод средств</h2>
                <hr>
            </div>  

            <div class="row">
                <div class="col-2 offset-3">
                    <div class="img-wrapper">
                        <img src="/icons/wallet_bw.svg" alt="">
                    </div>
                </div>
                <div class="col-5">
                    <div class="money-wrapper">
                        Доступно:
                        <div class="money-value" v-if="this.$store.state.user.id"><strong>{{this.$store.state.user.balanceStr}}</strong><sup>@</sup></div>
                    </div>
                </div>
            </div>

            <div class="text-center mb-4 mt-5">
                <h5>Заполните заявку:</h5>
            </div>

            <div v-if="!isSended">
                <div class="checks row mb-5">
                    <div class="col-6 offset-3">
                        <b-form-group>
                            <b-form-checkbox
                                :id="'checkbox1'"
                                :name="'checkbox-name1'"
                                :value="1"
                                v-model="form.pay_method_1"
                            >Наличные</b-form-checkbox>
                        </b-form-group>    
                        <b-form-group>
                            <b-form-checkbox
                                :id="'checkbox2'"
                                :name="'checkbox-name2'"
                                :value="1"
                                v-model="form.pay_method_2"
                            >Перевод на карту</b-form-checkbox>
                        </b-form-group>    
                        <b-form-group>
                            <b-form-checkbox
                                :id="'checkbox3'"
                                :name="'checkbox-name3'"
                                :value="1"
                                v-model="form.pay_method_3"
                            >Оплата по счету (с НДС)</b-form-checkbox>
                        </b-form-group>    
                        <b-form-group>
                            <b-form-checkbox
                                :id="'checkbox4'"
                                :name="'checkbox-name4'"
                                :value="1"
                                v-model="form.pay_method_4"
                            >Оплата по счету (без НДС)</b-form-checkbox>
                        </b-form-group>
                    </div>
                </div>

                <b-form-group>
                    <b-form-input
                        class="no-border-input"
                        v-model="form.bik"
                        :state="validateState('bik')"
                        placeholder="БИК"
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.form.bik"></FormInputValidate>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        class="no-border-input"
                        v-model="form.rs"
                        :state="validateState('rs')"
                        placeholder="р/с"
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.form.rs"></FormInputValidate>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        class="no-border-input"
                        v-model="form.ks"
                        :state="validateState('ks')"
                        placeholder="к/с"
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.form.ks"></FormInputValidate>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        class="no-border-input"
                        v-model="form.inn"
                        :state="validateState('inn')"
                        placeholder="ИНН"
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.form.inn"></FormInputValidate>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        class="no-border-input"
                        v-model="form.fio"
                        placeholder="ФИО"
                        :state="validateState('fio')"
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.form.fio"></FormInputValidate>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        class="no-border-input"
                        v-model="form.money"
                        placeholder="Сумма"
                        :state="validateState('money')"
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.form.money"></FormInputValidate>
                </b-form-group>

                <div class="text-center my-4">
                    <a class="text-dark shadow-button no-shadow" @click="sendRequest">
                        <span class="icon icon-minus">-</span> Вывести
                    </a>
                </div>
            </div>
            <div v-if="isSended">
                <b-alert variant="success" show>Заявка отправлена</b-alert>
            </div>
        </div>
    </div>
</template>


<script>

import { validationMixin } from "vuelidate";
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import FormInputValidate from "./Modules/FormInputValidate.vue";

export default {
    name: 'ProfileMoneyOut',
    components: {
      FormInputValidate
    },
    mixins: [validationMixin],
    data() {
      return {
        form: {},
        isSended: false
      }
    },
    validations: {
      form: {
        bik: {
          required,
          numeric,
        },
        rs: {
          required,
          numeric,
        },
        ks: {
          required,
          numeric,
        },
        inn: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(12),
        },
        fio: {
          required,
        },
        money: {
          required,
        },
      }
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      sendRequest(){
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.api.get('balance/out', { data: this.form})
        .then(function() {
            this.isSended = true;
        }.bind(this));
      },
    }
}
</script>