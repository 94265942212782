var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"index"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"orders-filter mb-4 clearfix"},[_c('a',{staticClass:"btn btn-sm mr-2",class:{
                    'btn-secondary': 'public' === _vm.tab,
                    'btn-outline-secondary': 'public' !== _vm.tab
                },attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.setTab('public')}}},[_vm._v("Опубликована")]),_c('a',{staticClass:"btn btn-sm mr-2",class:{
                    'btn-secondary': 'work' === _vm.tab,
                    'btn-outline-secondary': 'work' !== _vm.tab
                },attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.setTab('work')}}},[_vm._v("На заказе")]),_c('a',{staticClass:"btn btn-sm mr-2",class:{
                    'btn-secondary': 'archive' === _vm.tab,
                    'btn-outline-secondary': 'archive' !== _vm.tab
                },attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.setTab('archive')}}},[_vm._v("Архив")])]),_c('div',{staticClass:"orders"},_vm._l((_vm.items),function(tech){return _c('TechItem',{key:tech.id,attrs:{"tech":tech}})}),1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Мои услуги")]),_c('hr')])}]

export { render, staticRenderFns }