<template>
  <div id="index">
    <div class="container">
      <div class="text-center">
        <h2 class="mb-4">Добавить технику</h2>
        <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
        <h5>Укажите доп. характеристики</h5>
        <hr>
      </div> 

      <FormParams v-bind:elements="elements"></FormParams>

      <div class="clearfix">
          <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
          <div class="float-right"><a @click="nextStep" class="text-dark">Следующий шаг &rarr;</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import FormParams from '../../Modules/FormParams.vue'

export default {
    name: 'Additional',
    props: ['step', 'progress'],
    components: {
      FormParams
    },
    data() {
        return {
            elements: {}
        }
    },
    methods: {
      updateElements(){
        var self = this;
        self.api.get('reference-book/tech-params', {
            'tech_category_id': self.$parent.formData.category_id,
            'type_data_id': 2,
            'type_id': 2
        })
        .then(function(response) {
            self.elements = response.data;
        });
      },
      prevStep() {
        this.$parent.changeStep(this.step - 1);
      },
      nextStep() {
        this.$parent.setFormData({"additional": this.helpers.getValuesFromElements(this.elements)});
        this.$parent.changeStep(this.step + 1);
      }
    }
}
</script>