<template>
    <div id="index">
        <div class="container">
            <LinkBack></LinkBack>
            <div class="text-center order-major-title mb-4">
                <h2 class="mb-4 mt-3">Завершение</h2>
                <hr />
            </div>
            <h4 class="order-details-heading text-center mt-3">
                Укажите детали:
            </h4>
            <hr />
        </div>
        <div class="container">
        <div>
            <b-form-radio
                :id="'checkbox_status_1'"
                :name="'checkbox-status-done'"
                :value="1"
                v-model="form.status"
                :state="validateState('status')"
                 @change="updateValid"
                >Заказ успешно выполнен
            </b-form-radio>
            <b-form-radio
                :id="'checkbox_status_2'"
                :name="'checkbox-status-fail'"
                :value="2"
                v-model="form.status"
                :state="validateState('status')"
                 @change="updateValid"
                >Заказ отменен
            </b-form-radio>
            <template>              
                <div v-if="show_valid_error" style="margin-top:10px; font-size: 80%;color: #dc3545;">Нужно выбрать минимум один пункт</div>
            </template>
            </div>
            <div class="mt-5">
                <div v-if="1 === form.status">
                    <!--
                    <b-form-group
                        :id="'input-group-stars'"
                        :label="'Оцените работу исполнителя от 1 до 5 (звезды)'"
                        :label-for="'input-stars'"
                    >
                        <b-form-input
                            :id="'input-stars'"
                            v-model="form.stars"
                            required
                        ></b-form-input>
                    </b-form-group>
                    -->
                    <b-form-group
                        :id="'input-group-comment'"
                        :label="'Комментарий'"
                        :label-for="'input-comment'"
                    >
                        <b-form-input
                            :id="'input-comment'"
                            v-model="form.comment"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="2 === form.status">
                    <b-form-group
                        :id="'input-group-reason'"
                        :label="'Укажите причину отмены'"
                        :label-for="'input-reason'"
                    >
                        <b-form-input
                            :id="'input-reason'"
                            v-model="form.comment"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="mt-5">
                <a
                    href="javascript:"
                    class="btn btn-lg btn-secondary"
                    @click="submit"
                    >Завершить заказ
                </a>
            </div>
        </div>
    </div>
</template>


<script>
import LinkBack from "./../Modules/LinkBack.vue";
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators'
// import FormInputValidate from "./../Modules/FormInputValidate.vue";

export default {
    name: "OrderComplete",
    props: ["order_id", "client", "worker"],
    components: {
        LinkBack,
        // FormInputValidate
    },
    mixins: [validationMixin],
    data() {
        return {
            order: {},
            form: {},
            show_valid_error: false
        };
    },
     validations: {
      form: {
        status: {
          required,
        },
      }
    },
    created: function() {
        if (!this.order_id) {
            this.$router.push({ path: "/dashboard" });
        }
        this.api.get("order/get", { order_id: this.order_id }).then(
            function(response) {
                this.order = response.data;
                this.form.order_id = this.order.id;
            }.bind(this)
        );
    },
    methods: {
        updateValid(){
            this.show_valid_error = false;
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        submit: function() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                this.show_valid_error = true;
                return;
            }
            this.api.get("order/complete", { form: this.form }).then(
                function() {
                    if(this.client){
                        this.$router.push({ path: "/orders/client" });
                    }
                    if(this.worker){
                        this.$router.push({ path: "/orders/worker" });
                    }
                }.bind(this)
            );
        }
    }
};
</script>