<template>
    <div id="index">
        <div class="container">
            <div class="text-center">
                <h2 class="mb-4">Поиск заказов</h2>
                <hr />
            </div>

            <div class="orders-filter mb-4 clearfix">
                <div class="sorter float-right">
                    <a href="javascript:"
                        >Сортировка
                        <img src="icons/ascending-sort.svg" style="width: 14px"
                    /></a>
                </div>

                <a
                    href="#"
                    @click="setTabAll"
                    :class="{
                        'btn-secondary': 'all' === tab,
                        'btn-outline-secondary': 'all' !== tab,
                    }"
                    class="btn btn-sm mr-2"
                    >Все</a
                >
                <a
                    href="#"
                    @click="setTabToMe"
                    :class="{
                        'btn-secondary': 'tome' === tab,
                        'btn-outline-secondary': 'tome' !== tab,
                    }"
                    class="btn btn-sm w-label"
                    >Подходящие <span class="label">{{orders.tome}}</span></a
                >
                <OrderSearchModal></OrderSearchModal>
            </div>

            <div class="orders">
                <OrderItem
                    v-for="order in orders.items"
                    :key="order.id"
                    v-bind:worker="true"
                    v-bind:order="order"
                >
                </OrderItem>
            </div>
        </div>
    </div>
</template>

<script>
import OrderItem from "./OrderItem.vue";
import OrderSearchModal from "./OrderSearchModal.vue";

export default {
    name: "SearchOrder",
    components: {
        OrderItem,
        OrderSearchModal,
    },
    data() {
        return {
            orders: {},
            tab: "all",
            search: {},
        };
    },
    created: function () {
        this.getData();
    },
    methods: {
        getData: function () {
            this.api.get("order/search", { data: {tab: this.tab, search: this.search} }).then(
                function (response) {
                    this.orders = response.data;
                }.bind(this)
            );
        },
        setTabAll: function () {
            this.tab = "all";
            this.getData();
        },
        setTabToMe: function () {
            this.tab = "tome";
            this.getData();
        },
        setSearchData: function (form) {
            this.search = form;
            this.getData();
        },
    },
};
</script>