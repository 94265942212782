<template>
    <div id="index">
        <div class="container">
            <div class="text-center">
                <h2 class="mb-4">Мои заказы</h2>
                <hr />
            </div>

            <div class="orders-filter mb-4 clearfix">
                <a
                    href="javascript:"
                    @click="setTab(1)"
                    :class="{
                        'btn-secondary': 1 === tab,
                        'btn-outline-secondary': 1 !== tab,
                    }"
                    class="btn btn-sm mr-2"
                    >Откликнулся</a
                >
                <a
                    href="javascript:"
                    @click="setTab(2)"
                    :class="{
                        'btn-secondary': 2 === tab,
                        'btn-outline-secondary': 2 !== tab,
                    }"
                    class="btn btn-sm mr-2"
                    >В работе</a
                >
                <a
                    href="javascript:"
                    @click="setTab(3)"
                    :class="{
                        'btn-secondary': 3 === tab,
                        'btn-outline-secondary': 3 !== tab,
                    }"
                    class="btn btn-sm mr-2"
                    >Выполненные</a
                >
            </div>

            <div class="orders">
                <OrderItem
                    v-for="order in orders.items"
                    :key="order.id"
                    v-bind:worker="true"
                    v-bind:order="order"
                >
                </OrderItem>
            </div>
        </div>
    </div>
</template>


<script>
import OrderItem from "./OrderItem.vue";

export default {
    name: "MyOrdersWorker",
    components: {
        OrderItem,
    },
    data() {
        return {
            orders: {},
            tab: 1,
        };
    },
    created: function () {
        this.getData();
    },
    methods: {
        getData: function () {
            this.api.get("order/worker/my", { data: {tab: this.tab}}).then(
                function (response) {
                    this.orders = response.data;
                }.bind(this)
            );
        },
        setTab: function (value) {
            this.tab = value;
            this.getData();
        },
    },
};
</script>