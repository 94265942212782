<template>
  <div id="index">
    <div class="container">
      <div class="text-center">
        <h2 class="mb-4">Добавить технику</h2>
        <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
        <h5>Добавьте фото техники</h5>
        <hr>
      </div> 

      <b-form class="mb-5">
          <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="uploadPhoto"></vue-dropzone>
      </b-form>

      <div class="clearfix mt-2">
          <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
          <div class="float-right"><a @click="nextStep" class="text-dark">Следующий шаг &rarr;</a></div>
      </div>
      <br><br>
    </div>
  </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: 'Photo',
    components: {
      vueDropzone: vue2Dropzone
    },
    props: ['step', 'progress'],
    data() {
        return {
            photos: [],
            dropzoneOptions: {
                url: this.config.API_URL + 'upload/temp-photo',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { 
                  Accept: "application/json",
                  Authorization: "Bearer " + this.$store.state.token
                }
            }
        }
    },
    methods: {
      prevStep() {
        this.$parent.changeStep(this.step - 1);
      },
      nextStep() {
        this.$parent.setFormData({"photos": this.photos});
        this.$parent.changeStep(this.step + 2);
      },
      uploadPhoto(file, response) {
          this.photos.push(response);
      }
    }
}
</script>

<style>
.choose-label{
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
}
    .file-wrapper{
        position: relative;
    }
    .super-file{
        position:absolute;
        top: 80px;
        left: 0;
        bottom: 0;
        right: 0;
        width:100%;
        opacity:0;
        display: block;
        z-index:1000;
    }
</style>