<template>
    <div class="order-item">
        <h2>Заказ № {{ order.id }}</h2>
        <div class="order-detail"><strong>Услуга:</strong> {{ order.category }}</div>
        <div class="order-detail"><strong>Место работ:</strong> {{ order.location }}</div>
        <div class="order-detail"><strong>Дата и время:</strong> {{ order.datetimePeriod }}</div>
        
        <router-link :to="{ 'name': 'orderClientShow', 'params': { order_id: order.id } }" v-if="client">Подробнее &rarr;</router-link>
        <router-link :to="{ 'name': 'orderWorkerShow', 'params': { order_id: order.id } }" v-if="worker">Подробнее &rarr;</router-link>
    </div>
</template>

<script>
export default {
    name: 'OrderItem',
    props: ['order','client','worker'],
    data() {
        return {
 
        }
    },
    created: function () {
      
    },
    methods: {
        
    }
}
</script>
