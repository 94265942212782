<template>
  <div id="index">
    <div class="container">
      <div class="text-center">
        <h2 class="mb-4">Создание заказа</h2>
        <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
        <h5>Выберите услугу</h5>
        <hr>
      </div>     
      <div class="row">
            <div class="col-6 mb-4" v-for="category in categories" :key="category.value">
                <a @click="setCategory(category.value); nextStep();" class="text-dark">
                    <b-card>
                        <b-img blank blank-color="#ccc" width="32" alt="placeholder" class="img-fluid rounded-circle mb-2"></b-img>
                        <div>{{ category.text }}<span class="subtext text-yellow" style="padding-left:10px">{{ category.count_text }}</span></div>
                    </b-card>     
                </a>    
            </div>    
      </div>  
      <div class="clearfix">
          <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
      </div>  
    </div>
  </div>
</template>

<script>

export default {
    name: 'TechCategory',
    props: ['step', 'progress'],
    data() {
        return {
            categories: [],
            form: {}
        }
    },
    methods: {
      updateElements: function () {
        var self = this;
        self.categories = [];
        self.api.get('reference-book/tech-categories', {parent_id: this.$parent.formData.category_parent_id})
        .then(function(response) {
          self.categories = response.data;
        });
      },
      setCategory(category_id) {
        this.$parent.setFormData({"category_id": category_id});
      },
      nextStep() {
        this.$parent.changeStep(this.step + 1);
      },
      prevStep() {
          this.$parent.changeStep(this.step - 1);
      },
    }
}
</script>