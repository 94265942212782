<template>
  <div id="index">
    <div class="container">
      <div class="text-center">
        <b-img blank blank-color="#ccc" width="64" alt="placeholder" class="img-fluid rounded-circle mt-5"></b-img>
        <h2 class="mb-4 mt-3">Услуга добавлена</h2>
        <p>Спасибо. Карточка Вашей услуги отправлена на модерацию и появится в сервисе в ближайшее время</p>
        <router-link class="btn btn-lg btn-success mt-5" to="/tech/list">Мои услуги</router-link>
      </div> 
    </div>
  </div>
</template>
<script>
export default {
    name: 'Result'
}
</script>