<template>
  <div>
    <ChooseCategory v-show="1 === step" v-bind:step="1" v-bind:progress="0"></ChooseCategory>
    <Category       v-show="2 === step" v-bind:step="2" v-bind:progress="15" ref="category"></Category>
    <Params         v-show="3 === step" v-bind:step="3" v-bind:progress="30" ref="params"></Params>
    <Additional     v-show="4 === step" v-bind:step="4" v-bind:progress="45" ref="additional"></Additional>
    <Property       v-show="5 === step" v-bind:step="5" v-bind:progress="60"></Property>
    <Photo          v-show="6 === step" v-bind:step="6" v-bind:progress="75"></Photo>
    <Money          v-show="7 === step" v-bind:step="7" v-bind:progress="85"></Money>
    <Location       v-show="8 === step" v-bind:step="8" v-bind:progress="90"></Location>
    <Result         v-show="9 === step"></Result>
  </div>
</template>

<script>
import ChooseCategory from './Create/ChooseCategory.vue'
import Category   from './Create/Category.vue'
import Additional from './Create/Additional.vue'
import Params     from './Create/Params.vue'
import Photo      from './Create/Photo.vue'
import Property   from './Create/Property.vue'
import Result     from './Create/Result.vue'
import Money      from './Create/Money.vue'
import Location   from './Create/Location.vue'

export default {
    name: 'CreateTech',
    components: {
      ChooseCategory,
      Category,
      Additional,
      Params,
      Photo,
      Property,
      Result,
      Money,
      Location
    },
    data() {
      return {
        step: 1,
        formData: {}
      }
    },
    methods: {
      changeStep(step) {
        if(1 === this.step && 2 === step){
          this.$refs.category.updateElements();
        }
        if(2 === this.step && 3 === step){
          // обновляем форму доп. параметров, т.к. был переход из первой категории (где она могла быть изменены)
          this.$refs.params.updateElements();
          this.$refs.additional.updateElements();
        }
        this.step = step;
      },
      setFormData(data) {
        for (var attrname in data) { 
          this.formData[attrname] = data[attrname]; 
        }
        // console.log(this.formData);
      },
      saveTech() {
        let self = this;
        return self.api.get('tech/create', {
          'data': self.formData,
        });
      }
    }
}
</script>