<template>
    <div id="index">
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="mb-4">Создание заказа</h2>
                <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
                <h5>Опишите,<br>что нужно сделать</h5>
                <hr>
            </div>     
            
            <b-form-group :id="'input-group'">
                <b-form-textarea
                    class="no-border-input"
                    :id="'textarea'"
                    placeholder="Описание работ"
                    :state="validateState('todo')"
                    v-model="form.todo"
                ></b-form-textarea>
                <FormInputValidate :vdata="$v.form.todo"></FormInputValidate>
            </b-form-group>

            <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="uploadPhoto"></vue-dropzone>

            <!-- <div class="file-input-wrapper text-center mb-5">
                 <img src="icons/file.svg" alt="">
                 Добавить фото объекта
                 <input type="file" name="" id="">
            </div> -->

            <div class="clearfix">
                <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
                <div class="float-right"><a @click="nextStep" class="text-dark">Следующий шаг &rarr;</a></div>
            </div>  

            <!-- <div class="text-center text-yellow font-italic mt-5">
                {{this.$parent.offerCount}} подходящих предложений
            </div> -->
        </div>
    </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators'
import FormInputValidate from "../../Modules/FormInputValidate.vue";

export default {
    name: 'DateTime',
    props: ['step', 'progress'],
    mixins: [validationMixin],
    components: {
      vueDropzone: vue2Dropzone, FormInputValidate
    },
    data() {
        return {
            form: {},
            photos: [],
            dropzoneOptions: {
                url: this.config.API_URL + 'upload/temp-photo',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { 
                  Accept: "application/json",
                  Authorization: "Bearer " + this.$store.state.token
                }
            }
        }
    },
    validations: {
        form: {
            todo: {
                required,
            },
        }
    },
    created: function () {
      
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        prevStep() {
            this.$parent.changeStep(this.step - 1);
        },
        nextStep() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.$parent.setFormData({
                "photos": this.photos,
                "todo": this.form.todo
            });
            this.$parent.changeStep(this.step + 1);
        },
        uploadPhoto(file, response) {
            this.photos.push(response);
        }
    }
}
</script>