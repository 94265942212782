<template>
    <div id="index">
        <div class="container">
            <div class="text-center mt-5 mb-4">
                <h2 class="mb-4">Мой баланс</h2>
                <hr>
            </div>  

            <div class="row">
                <div class="col-2 offset-3">
                    <div class="img-wrapper">
                        <img src="/icons/wallet_bw.svg" alt="">
                    </div>
                </div>
                <div class="col-5">
                    <div class="money-wrapper">
                        Доступно:
                        <div class="money-value"><strong>{{this.$store.state.user.balanceStr}}</strong><sup>@</sup></div>
                    </div>
                </div>
            </div>

            <div class="text-center mt-3 mb-5">
                <div class="grid-buttons">
                    <a href="javascript:" class="text-dark shadow-button" v-b-modal.fill-money-modal><span class="icon icon-plus">+</span> Пополнить</a>
                    <!--<router-link to="/profile/money-out" class="text-dark shadow-button no-shadow"><span class="icon icon-minus">-</span> Вывести</router-link>-->
                    <router-link to="/profile/referals" class="text-dark shadow-button">Реферальная программа</router-link>
                    <!--<router-link to="/profile/referals" class="text-dark shadow-button grid-block">Реферальная программа</router-link>-->
                </div>
            </div>

            <h5 class="container-header">История операций:</h5>
            <table class="table table-borderless bb-table">
                <tr v-for="(item, key) in history" :key="key">
                    <td >
                        {{item.type}}
                    </td>
                    <td v-bind:class="['text-right', 2 === parseInt(item.type_id) ? 'text-danger' : 'text-success']">
                        {{item.change}} Р
                    </td>
                </tr>
            </table>

        </div>

        <b-modal id="fill-money-modal" title="Пополнение баланса" hide-footer hide-header>
            <div class="text-center pb-5">
                <div class="row">
                    <div class="col-8 offset-2">
                        <p class="my-5"><strong>Укажите сумму:</strong></p>
                        <b-form-group :id="'input-group'">
                            <b-form-input
                                class="no-border-input"
                                :id="'input'"
                                v-model="sum"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <a href="javascript:" class="btn text-dark shadow-button active" @click="actionPay"><span class="icon icon-plus">+</span> Пополнить</a>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>

export default {
    name: 'ProfileMoney',
    data() {
      return {
        sum: 300,
        history: []
      }
    },
    created: function () {
        var self = this;
        self.api.get('balance/history')
        .then(function(response) {
            self.history = response.data;
        }); 
    },
    methods: {
      actionPay() {
        this.api.get('pay', {'sum': this.sum, 'returnUrl': 'https://astis.online/#/profile/paysuccess'})
        .then(function(response) {
            if('success' === response.data.status){
                window.location.replace(response.data.pay_link);
            }
            console.log(response);
        });
      },
    }
}
</script>