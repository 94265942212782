<template>
    <div id="index">
        <div class="container">
            <div class="text-center">
                <h2 class="mb-4">Мои услуги</h2>
                <hr />
            </div>

            <div class="orders-filter mb-4 clearfix">
                <a
                    href="javascript:"
                    @click="setTab('public')"
                    :class="{
                        'btn-secondary': 'public' === tab,
                        'btn-outline-secondary': 'public' !== tab
                    }"
                    class="btn btn-sm mr-2"
                    >Опубликована</a
                >
                <a
                    href="javascript:"
                    @click="setTab('work')"
                    :class="{
                        'btn-secondary': 'work' === tab,
                        'btn-outline-secondary': 'work' !== tab
                    }"
                    class="btn btn-sm mr-2"
                    >На заказе</a
                >
                <a
                    href="javascript:"
                    @click="setTab('archive')"
                    :class="{
                        'btn-secondary': 'archive' === tab,
                        'btn-outline-secondary': 'archive' !== tab
                    }"
                    class="btn btn-sm mr-2"
                    >Архив</a
                >
            </div>

            <div class="orders">
                <TechItem
                    v-for="tech in items"
                    :key="tech.id"
                    v-bind:tech="tech"
                >
                </TechItem>
            </div>
        </div>
    </div>
</template>


<script>
import TechItem from "./TechItem.vue";

export default {
    name: "ListTech",
    components: {
        TechItem
    },
    data() {
        return {
            tab: "public",
            items: []
        };
    },
    created: function() {
        this.getData();
    },
    methods: {
        getData: function() {
            this.api.get("tech/my", { data: { tab: this.tab } }).then(
                function(response) {
                    this.items = response.data.items;
                }.bind(this)
            );
        },
        setTab: function(value) {
            this.tab = value;
            this.getData();
        }
    }
};
</script>