<template>
    <div v-if="!order.worker_id">
        <div class="container">
            <div class="text-center mt-5" v-if="true == order.alreadyRespondByMe">
                Вы уже откликнулись на этот заказ
            </div>
            <div class="text-center mt-5" v-if="showResponse != true && false == order.alreadyRespondByMe">
                <a
                    href="javascript:"
                    class="btn btn-lg btn-outline-secondary"
                    @click="expandResponse"
                    >Откликнуться</a
                >
            </div>

            <div class="response-form-wrapper" v-if="showResponse">
                <h4 class="order-details-heading text-center mt-5">
                    1. Выберите технику:
                </h4>
                <b-form-group class="mx-4 mt-2">
                    <b-form-select
                        :id="'select'"
                        class="no-border-input"
                        :options="techList"
                        :state="validateState('tech_id')"
                        v-model="respond.tech_id"
                        required
                    ></b-form-select>
                    <FormInputValidate :vdata="$v.respond.tech_id"></FormInputValidate>
                </b-form-group>

                <h4 class="order-details-heading text-center mt-5">
                    2. Укажите стоимость в час:
                </h4>
                <b-form-group class="mx-4">
                    <b-form-input
                        :id="'input-price_hour'"
                        class="no-border-input"
                        v-model="respond.price_hour"
                        placeholder="1500"
                        :state="validateState('price_hour')"
                        required
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.respond.price_hour"></FormInputValidate>
                </b-form-group>

                <h4 class="order-details-heading text-center mt-5">
                    3. Укажите стоимость доставки:
                </h4>
                <b-form-group class="mx-4">
                    <b-form-input
                        :id="'input-price_delivery'"
                        class="no-border-input"
                        v-model="respond.price_delivery"
                        placeholder="3000"
                        :state="validateState('price_delivery')"
                        required
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.respond.price_delivery"></FormInputValidate>
                </b-form-group>

                <h4 class="order-details-heading text-center mt-5">
                    4. Укажите минимальное время работы:
                </h4>
                <b-form-group class="mx-4">
                    <b-form-input
                        :id="'input-min_time'"
                        class="no-border-input"
                        v-model="respond.min_time"
                        placeholder="2 часа"
                        :state="validateState('min_time')"
                        required
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.respond.min_time"></FormInputValidate>
                </b-form-group>

                <h4 class="order-details-heading text-center mt-5">
                    5. Способ оплаты
                </h4>
                <div class="mx-4">
                    <b-form-group>
                        <b-form-checkbox
                            :id="'checkbox1'"
                            :name="'checkbox-name1'"
                            :value="1"
                            v-model="respond.pay_method_1"
                        >Наличные</b-form-checkbox>
                    </b-form-group>    
                    <b-form-group>
                        <b-form-checkbox
                            :id="'checkbox2'"
                            :name="'checkbox-name2'"
                            :value="1"
                            v-model="respond.pay_method_2"
                        >Перевод на карту</b-form-checkbox>
                    </b-form-group>    
                    <b-form-group>
                        <b-form-checkbox
                            :id="'checkbox3'"
                            :name="'checkbox-name3'"
                            :value="1"
                            v-model="respond.pay_method_3"
                        >Оплата по счету (с НДС)</b-form-checkbox>
                    </b-form-group>    
                    <b-form-group>
                        <b-form-checkbox
                            :id="'checkbox4'"
                            :name="'checkbox-name4'"
                            :value="1"
                            v-model="respond.pay_method_4"
                        >Оплата по счету (без НДС)</b-form-checkbox>
                    </b-form-group>
                    <template>              
                        <div v-if="pay_method_error" style="font-size: 80%;color: #dc3545;">Нужно выбрать минимум один пункт</div>
                    </template>
                </div> 

                <h4 class="order-details-heading text-center mt-5">
                    6. Добавить комментарий:
                </h4>
                <b-form-group class="mx-4">
                    <b-form-input
                        :id="'input-comment'"
                        class="no-border-input"
                        v-model="respond.comment"
                        placeholder="Комментарий"
                        :state="validateState('comment')"
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.respond.comment"></FormInputValidate>
                </b-form-group>
                <div class="text-center mt-5 pb-5">
                    <a
                        href="javascript:"
                        class="btn btn-lg btn-outline-secondary"
                        @click="createRespond"
                        >Откликнуться</a
                    >
                </div>
            </div>
        </div>
        <b-modal
            id="workerResponseModal"
            title="Откликнуться"
            hide-footer
            hide-header
        >
            <div class="text-center">
                <h4 class="mb-5 mt-5">Отклик отправлен!</h4>
                <img src="icons/clock.svg" style="width: 50px" alt="" />
                <p class="mt-4">
                    Дождитесь выбора заказчика,<br />Вы получите уведомление по
                    данному заказу
                </p>
                <div class="mt-5">
                    <router-link
                        to="/orders/worker"
                        class="btn btn-outline-secondary btn-lg btn-block mt-3"
                        >Мои заказы</router-link
                    >
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from 'vuelidate/lib/validators'
import FormInputValidate from "../Modules/FormInputValidate.vue";

export default {
    name: "OrderRespondModal",
    props: ["order"],
    data() {
        return {
            showResponse: false,
            techList: [],
            respond: {
                pay_method_1: false,
                pay_method_2: false,
                pay_method_3: false,
                pay_method_4: false,
            },
            pay_method_error: false,
        };
    },
    validations: {
      respond: {
        tech_id: {
          required,
        },
        price_hour: {
          required,
        },
        price_delivery: {
          required,
        },
        min_time: {
          required,
        },
        comment: {
          maxLength: maxLength(999999),
        },
      }
    },
    components: {
      FormInputValidate,
    },
    mixins: [validationMixin],
    created: function() {
        this.api.get("reference-book/mytech").then(
            function(response) {
                this.techList = response.data;
            }.bind(this)
        );
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.respond[name];
            return $dirty ? !$error : null;
        },
        createRespond() {
            console.log(this.respond);
            if(
                1 === this.respond.pay_method_1 
                || 1 === this.respond.pay_method_2
                || 1 === this.respond.pay_method_3
                || 1 === this.respond.pay_method_4
            ) {
                this.pay_method_error = false;
            } else {
                this.pay_method_error = true;
            }
            this.$v.respond.$touch();
            if (this.$v.respond.$anyError) {
                return;
            }
            this.respond.order_id = this.order.id;            
            this.api.get("respond/create", { data: this.respond }).then(
                function() {
                    this.$bvModal.show("workerResponseModal");
                    this.showResponse = false;
                }.bind(this)
            );
        },
        expandResponse() {
            this.showResponse = true;
        }
    }
};
</script>
