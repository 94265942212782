<template>
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <div class="text-center">
              <div>
                <b-form>
                    <div class="text-left mt-5">
                      <h2 class="py-3">Сбросить пароль</h2>
                      <p>Чтобы сбросить пароль<br>введите ваш телефон</p>
                    </div>
                    <b-form-group>
                        <b-form-input
                            id="phone"
                            v-model="form.phone"
                            type="text"
                            class="no-border-input"
                            :state="validateState('phone')"
                            placeholder="79991002030"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.phone"></FormInputValidate>
                    </b-form-group>
                    <b-button @click="onPhoneCheck()" class="my-4 btn btn-lg btn-secondary my-4">Продолжить</b-button>
                </b-form>    
              </div>                                                                                
          </div>
        </div>
      </div>
      <ModalAuthPhone/>
    </div>
</template>


<script>
import ModalAuthPhone from './ModalAuthPhone.vue'
import { validationMixin } from "vuelidate";
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import FormInputValidate from "./Modules/FormInputValidate.vue";

export default {
    data() {
      return {
        form: {
          phone: '',
        }
      }
    },
    validations: {
      form: {
        phone: {
          required,
          numeric,
          minLength: minLength(11),
          maxLength: maxLength(11),
        },
      }
    },
    components: {
      ModalAuthPhone,
      FormInputValidate,
    },
    mixins: [validationMixin],
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onPhoneCheck() {
        var self = this;
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        self.api.get('register/phone/check', {"phone": self.form.phone, "resetPassword": true})
        .then(function(response) {
          console.log(response);
          self.$store.state.phone = self.form.phone;
          self.$bvModal.show('modalAuthPhone');
        });       
      },
    }   
}
</script>