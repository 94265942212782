<template>
  <div id="index">
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <div class="text-left">
            какая-то страница
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'Index',
}
</script>