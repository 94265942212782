import Index from './components/Index.vue'
import Login from './components/Login.vue'
import ResetPassword from './components/ResetPassword.vue'
import Register from './components/Register.vue'
import RegisterProfile from './components/RegisterProfile.vue'
import MyProfile from './components/MyProfile.vue'
import MyProfileEdit from './components/MyProfileEdit.vue'
import ShowContact from './components/ShowContact.vue'
import DashBoard from './components/DashBoard.vue'
import CreateTech from './components/Tech/CreateTech.vue'
import ListTech from './components/Tech/ListTech.vue'
import TechDetails from './components/Tech/TechDetails.vue'
import CreateOrder from './components/Orders/CreateOrder.vue'
import SearchOrder from './components/Orders/SearchOrder.vue'
import OrderDetailsWorker from './components/Orders/OrderDetailsWorker.vue'
import MyOrdersWorker from './components/Orders/MyOrdersWorker.vue'
import MyOrdersClient from './components/Orders/MyOrdersClient.vue'
import OrderDetailsClient from './components/Orders/OrderDetailsClient.vue'
import OrderComplete from './components/Orders/OrderComplete.vue'
import ProfileMoney from './components/ProfileMoney.vue'
import ProfileMoneyOut from './components/ProfileMoneyOut.vue'
import ProfileReferals from './components/ProfileReferals.vue'
import MyNotifications from './components/Notifications/MyNotifications.vue'
import PaySuccess from './components/PaySuccess.vue'
import Chat from './components/Chat/Chat.vue'
import Refs from './components/Refs/Refs.vue'

const routes = [
    { path: '/index', component: Index },
    { path: '/login', component: Login },
    { path: '/reset', component: ResetPassword },
    { path: '/register', component: Register },
    { path: '/register/profile', component: RegisterProfile },
    { path: '/profile', component: MyProfile },
    { path: '/profile/edit', component: MyProfileEdit },
    { path: '/contact/show', name: 'showContact', component: ShowContact, props: true },
    { path: '/dashboard', component: DashBoard },
    { path: '/tech/add', component: CreateTech },
    { path: '/tech/list', component: ListTech }, //Усуга (Ранее была техника)
    { path: '/tech/show', name: 'techShow', component: TechDetails, props: true }, //Техника
    { path: '/orders/create', component: CreateOrder }, //Заказчик. Создание заказа
    { path: '/orders/search', component: SearchOrder }, //Исполнитель. Поиск заказа
    { path: '/orders/worker', component: MyOrdersWorker }, //Заказчик. Список заказов
    { path: '/orders/worker/show', name: 'orderWorkerShow', component: OrderDetailsWorker, props: true }, //Исполнитель. Детальная заказа
    { path: '/orders/client', name:'myOrdersClient', component: MyOrdersClient }, //Заказчик. Список заказов
    { path: '/orders/client/show', name: 'orderClientShow', component: OrderDetailsClient, props: true }, //Заказчик. Детальная заказа
    { path: '/orders/complete', name: 'orderComplete', component: OrderComplete, props: true }, //Заказчик. Детальная заказа
    
    { path: '/profile/money', component: ProfileMoney },
    { path: '/profile/money-out', component: ProfileMoneyOut },
    { path: '/profile/paysuccess', component: PaySuccess },
    { path: '/profile/referals', component: ProfileReferals },
    { path: '/notifications', component: MyNotifications },
    { path: '/chat', name: 'chat', component: Chat, props: true },
    { path: '/ref', component: Refs },
  ]

export default routes;