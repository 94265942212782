<template>
  <div id="index">
    <div class="container">
      <div class="text-center">
        <h2 class="mb-4">Создание заказа</h2>
        <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
        <h5>Выберите категорию</h5>
        <hr>
      </div>     
      <div class="row">
            <div class="col-12 mb-4" v-for="category in categories" :key="category.value">
                <a @click="setCategory(category.value); nextStep();" class="text-dark block-link active">
                      <div class="img-wrapper">
                        <img src="icons/excavator_bw.svg" alt="">
                      </div>
                      <div>
                        {{ category.text }}
                        <span class="subtext text-yellow">{{ category.count_text }}</span>
                      </div>
                </a>    
            </div>    
            <!-- <div class="col-12 mb-4">
                <a href="javascript:" class="text-dark block-link">
                  <div class="img-wrapper">
                    <img src="icons/truck.svg" alt="">
                  </div>
                  <div>
                    Грузоперевозки
                    <span class="subtext text-yellow">Раздел недоступен</span>
                  </div>
                </a>    
            </div> -->
      </div>  
    </div>
  </div>
</template>

<script>

export default {
    name: 'ChooseCategory',
    props: ['step', 'progress'],
    data() {
        return {
            categories: [],
            form: {},
            // offerCount: 0,
        }
    },
    created: function () {
      var self = this;
      self.api.get('reference-book/parent-categories')
      .then(function(response) {
        self.categories = response.data;
      });
      // this.getOffer();
    },
    methods: {
      setCategory(category_id) {
        this.$parent.setFormData({"category_parent_id": category_id});
      },
      // getOffer(){
      //   this.api.get("tech/offers").then(
      //       function (response) {
      //           this.offerCount = response.data;
      //       }.bind(this)
      //   );
      // },
      nextStep() {
        this.$parent.changeStep(this.step + 1);
      }
    }
}
</script>