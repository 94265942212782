<template>
  <div id="index">
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <router-link :to="{ 'name': 'orderClientShow', 'params': { order_id: order_id } }" v-if="client">Назад</router-link>
          <router-link :to="{ 'name': 'orderWorkerShow', 'params': { order_id: order_id } }" v-if="worker">Назад</router-link>
          <h2>Контакты</h2>
          <hr>
          <div class="">
            <img v-bind:src="user.avatar" class="img-fluid rounded-circle">
            <div>ФИО: {{user.fullname}}</div>
            <div>Телефон: {{user.phone}}</div>
            <div>Email: {{user.email ? user.email : 'не указан'}}</div>
            <div>ИНН: {{user.inn}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'ShowContact',
    props: ['user_id', 'worker', 'client', 'order_id'],
    data() {
        return {
            user: {}
        };
    },
    created: function() {
        if (!this.user_id) {
            if(this.client) {
              this.$router.push({ path: "/orders/client" });
            } else {
              this.$router.push({ path: "/orders/search" });
            }
        }
        this.api.get("user-info", { user_id: this.user_id }).then(
            function(response) {
                this.user = response.data;
            }.bind(this)
        );
    },
}
</script>