<template>
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <h2>Мой профиль</h2>
          <div>Оплата успешно произведена</div>
          <hr>
          <div>После подтверждения платежа сумма будет начислена на ваш счет</div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name: 'PaySuccess',
}
</script>