export default class ApiAstis {
  constructor(app, store) {
    this.app = app;
    this.store = store;
  }
  get(url, options) {
    if(undefined === options) {
      options = {};
    }
    return this.app.axios.post(
      this.app.config.API_URL + url, 
      options, 
      { 
        headers: { 
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.store.state.token
        } 
      }
    ).catch(function(response) {
      console.log(response);
    });
  }
}