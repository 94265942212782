<template>
    <div id="index">
        <div class="container">
            <div class="text-center">
                <h2 class="mb-4">Уведомления</h2>
                <hr />
            </div>

            <div class="orders-filter mb-4 clearfix">
                <a
                    href="javascript:"
                    class="btn btn-sm mr-2"
                    :class="{
                        'btn-secondary': 0 === tab_readed,
                        'btn-outline-secondary': 0 !== tab_readed,
                    }"
                    @click="setTab(0)"
                    >Новые</a
                >
                <a
                    href="javascript:"
                    class="btn btn-sm mr-2"
                    :class="{
                        'btn-secondary': 1 === tab_readed,
                        'btn-outline-secondary': 1 !== tab_readed,
                    }"
                    @click="setTab(1)"
                    >Прочитано</a
                >
            </div>

            <div class="orders">
                <div v-if="0 === items.length">Уведомлений нет</div>
                <div v-for="(item, key) in items" :key="key">
                    <div class="order-item">
                        <div>
                            <div>{{ item.msg }}</div>
                            <div style="font-size: 12px;">{{ item.date }}</div>
                            <div style="font-size: 12px;">{{ item.dateReaded }}</div>
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyNotifications",
    components: {
        
    },
    data() {
        return {
            items: [],
            tab_readed: 0
        };
    },
    created: function() {
       this.getData();
    },
    methods: {
        getData: function() {
            this.api.get("notifications/get", { data: { user_type_id: this.$store.state.user.role_id, readed: this.tab_readed } }).then(
                function(response) {
                    this.items = response.data.data;
                    console.log(this.items);
                }.bind(this)
            );
        },
        setTab: function(status) {
            this.tab_readed = status;
            this.getData();
        }
    }
};
</script>