<template>
    <div>
        <div class="order-item" @click="showModal();">
            <div>
                <div class="inline-flex">
                    <div class="mr-2">
                        <div class="worker-tech-img">
                            <img src="icons/excavator_bw.svg" alt="">
                        </div>    
                    </div>
                    <div class="">
                        <h2 class="mb-1">{{respond.techTitle}}</h2>
                        <!-- <div class="rating">
                            <div class="rating-stars mr-2">
                                <i class="star"></i>
                                <i class="star"></i>
                                <i class="star"></i>
                                <i class="star"></i>
                                <i class="star"></i>
                            </div>
                            <div class="rating-value"><strong>4.9</strong></div>
                        </div> -->
                        <!-- <div class="order-detail">На сервисе более 1 года</div> -->
                    </div>
                </div>
                <div style="margin-top:10px;">
                    <div>{{respond.priceTitle}}</div>
                    <div>Оплата: {{respond.payTitle}}</div>
                    <div>Комментарий: {{respond.comment}}</div>
                    <div style="max-width: 600px;">
                        <strong>Характеристики техники:</strong>
                        <div class="clearfix"
                            v-for="param in respond.tech.params"
                            :key="param">
                            <div class="float-right"><span>{{param.text}}</span></div>
                            {{param.label}}
                        </div>
                    </div>
                </div>
            </div>
            
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'RespondsOrder',
    props: ['respond', 'modal'],
    methods: {
        showModal() {
            if(this.modal){
                this.$parent.$refs['modal-choose-worker'].show();
                this.$parent.selectRespond = this.respond;
            }
        }
    }
}
</script>
